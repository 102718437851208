import { makeStyles } from "tss-react/mui";
import AuthLayout from "./AuthLayout";
import { useState } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router";
import { useAppDispatch } from "src/redux/hooks";
import { isEmpty, isValidEmail, maxCharacters } from "src/helpers/validation-utils";
import { cloneObjectBasic } from "src/helpers/utils";
import { IUserStore } from "src/redux/user/types";
import { LoginUser } from "src/redux/user/userReducer";
import GenericInput from "src/components/input-components/GenericTextInput";
import { DataService } from "src/services/data-service";
import GenericButton from "src/components/GenericButton";


interface ILoginApiResponse {
    success: boolean;
    token: string;
    message: string
    data: {
      email: string;
      active: boolean;
      username: string;
      mobileNumber: string;
    }
}

const useStyles = makeStyles()((theme) => {
  return {
    root: {
      width: "500px",
      minHeight: "200px",
      maxWidth: "90%",
      backgroundColor: "white",
      borderRadius: "20px",
      padding: "45px 25px",
    },
    title: {
      margin: "0 auto 20px",
      textAlign: "center",
    },
    button: {
      margin: "15px auto",
      display: "block",
    },

  };
});

interface ILoginPageFormState {
  values: {
    email: string;
    password: string;
  };
  errors: {
    email: string;
    password: string;
  };
  touched: {
    email: boolean;
    password: boolean;
  };
}

const getInitialLoginFormState = (): ILoginPageFormState => {
  return {
    values: {
      email: "",
      password: "",
    },
    errors: {
      email: "",
      password: "",
    },
    touched: {
      email: false,
      password: false,
    },
  };
};

const LoginPage: React.FC = () => {
  const { classes } = useStyles();

  const [pageState, setPageState] = useState<ILoginPageFormState>(
    getInitialLoginFormState()
  );

  const [submitting, setSubmitting] = useState<boolean>(false);

  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const onValueChange = (name: string, value: string) => {
    const newPageState = cloneObjectBasic(pageState);
    if (name === "email") {
      newPageState.values.email = value;
      newPageState.errors.email = "";
    } else if (name === "password") {
      newPageState.values.password = value;
      newPageState.errors.password = "";
    }

    setPageState(newPageState);
  };

  const onBlur = (name: string) => {
    const newPageState = cloneObjectBasic(pageState);
    if (name === "email") {
      newPageState.touched.email = true;
      newPageState.errors.email = validateField(name, pageState.values.email);
    } else if (name === "password") {
      newPageState.touched.password = true;
      newPageState.errors.password = validateField(
        name,
        pageState.values.password
      );
    }
    setPageState(newPageState);
  };

  const validateField = (name: string, value: string): string => {
    if (name === "email") {
      if (isEmpty(value)) {
        return "Required";
      } else if (!isValidEmail(value)) {
        return "Invalid Email!";
      }
    } else if (name === "password") {
      if (isEmpty(value)) {
        return "Required";
      }

      if (!maxCharacters(value, 50)) {
        return "cannot be more than 50 characters";
      }
    }

    return "";
  };

  const validateForm = () => {
    const newPageState = cloneObjectBasic(pageState);

    newPageState.errors = {
      email: validateField("email", newPageState.values.email),
      password: validateField("password", newPageState.values.password),
    };

    newPageState.touched = {
      email: true,
      password: true,
    };

    setPageState(newPageState);

    return !(newPageState.errors.email || newPageState.errors.password);
  };

  const submit = async () => {
    if (!submitting) {
      setSubmitting(true);
      const isValid = validateForm();
      if (isValid) {
        const data = {
          email: pageState.values.email,
          password: pageState.values.password,
        };

        const loginRequest = await DataService.post(
          "/api/user/login",
          data,
          undefined
        );

        if (loginRequest.ok) {
          const result: ILoginApiResponse = await loginRequest.json();

          const newUser: IUserStore = {
            active: true,
            isAuthenticated: true,
            user: result.data,
            token: result.token,
            role: "Admin"
          };

          dispatch(LoginUser(newUser));
          setSubmitting(false);
          toast.success("Success");
          navigate("/");
        } else if (loginRequest.status === 401) {
          toast.error("Invalid Credentials");
          setSubmitting(false);
        } else {
          toast.error("Error had occured, try again later!");
          setSubmitting(false);
        }
      } else {
        setSubmitting(false);
      }
    }
  };

  return (
    <AuthLayout>
      <form className={classes.root} onSubmit={(e) => {
        e.preventDefault();
        submit();
      }}>
        <h1 className={classes.title}>Login</h1>
        <div>
          <GenericInput
            title="Email"
            type="text"
            value={pageState.values.email}
            onChange={(v) => onValueChange("email", v)}
            name="email"
            error={pageState.touched.email ? pageState.errors.email : ""}
            onBlur={onBlur}
            disabled={submitting}
          />

          <GenericInput
            title="Password"
            type="password"
            value={pageState.values.password}
            onChange={(v) => onValueChange("password", v)}
            name="password"
            error={pageState.touched.password ? pageState.errors.password : ""}
            disabled={submitting}
          />

          <GenericButton
            type="submit"
            // color="primary"
            variant="contained"
            className={classes.button}
            onClick={submit}
            disabled={submitting}
            text="Login"
          />
        </div>
      </form>
    </AuthLayout>
  );
};

export default LoginPage;

import { Modal } from '@mui/material';
import { DynamicField, DynamicFieldTypes } from 'src/components/dynamic-widget';
import DynamicFormWidget from 'src/components/dynamic-widget/DynamicFormWidget';
import { DataService } from 'src/services/data-service';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { FileType } from 'src/helpers/file-utils';
import GenericDrawer from 'src/components/drawer/GenericDrawer';

interface IAddDiscountProps {
  onSuccess: () => void;
  onClose: () => void;
}

const AddResturant: React.FC<IAddDiscountProps> = ({ onSuccess, onClose }) => {
  const navigate = useNavigate();

  const formConfig: Record<string, DynamicFieldTypes> = {
    name: {
      type: DynamicField.TEXT,
      required: true,
      name: 'Name',
      title: 'Name',
      value: '',
      disabled: false
    },
    password: {
      type: DynamicField.TEXT,
      inputType: 'password',
      required: true,
      name: 'Password',
      title: 'Password',
      value: '',
      disabled: false
    },

    email: {
      type: DynamicField.TEXT,
      required: false,
      name: 'Email',
      title: 'Email',
      inputType: 'email',
      value: '',
      disabled: false
    },
    mobileNumber: {
      type: DynamicField.PHONENUMBER,
      required: false,
      name: 'MobileNumber',
      title: 'Mobile Number',
      value: '',
      disabled: false
    },
    description: {
      type: DynamicField.TEXTAREA,
      required: true,
      name: 'Description',
      title: 'Description',
      value: '',
      disabled: false
    },
    file: {
      type: DynamicField.FILE,
      allowedTypes: [FileType.PNG, FileType.JPEG, FileType.JPG],
      required: true,
      maxSizeInMbs: 5,
      name: 'file',
      title: 'Restaurant File',
      value: '',
      disabled: false
    }
  };

  const submit = async (data) => {
    console.log(data);
    let data2 = {
      Name: data.Name,
      Description: data.Description,
      MobileNumber: data.MobileNumber,
      Password: data.Password,
      Email: data.Email
    };
    console.log(JSON.stringify(data2));

    const formData = new FormData();
    const file: File = data?.file;
    if(file){
      formData.append('request', JSON.stringify(data2));
      formData.append('formFile', file, file.name);
    }

    const req = await DataService.postForm('/api/Restaurant/create', formData);

    if (req.ok) {
      await onSuccess();
      return true;
    } else {
      toast.error('An Error occured!');
    }
    return false;
  };

  return (
    <>
      <GenericDrawer title={'Add Restuarant'} open={true} onClose={onClose}>
        <DynamicFormWidget
          title=""
          drawerMode
          fields={formConfig}
          onSubmit={async (e) => {
            return await submit(e);
          }}
        />
      </GenericDrawer>
    </>
  );
};

export default AddResturant;
